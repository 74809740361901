<template lang="html">
  <div class="container-fluid contain">
    <div class="">
      <img src="@/assets/images/icon_arrow_left.png" style="width: 18px" @click="$router.go(-1)"/>
      <div class="login-title" style="margin-top: 32px; margin-bottom: 10px; text-align: left; font-size: 35px;">PAYMENT
        METHODS
      </div>
      <div style="position: relative; padding-top: 30px;">
        <div
          style="width: 25px; font-size: 11px; color: grey; font-weight: 700; text-align: center; margin-left: 7px; margin-bottom: -10px;"
          v-if="get(state, 'currentUser.paymentMethods', []).filter(p => p.type === 'payment' && p.isValid).length > 0">
          DEFAULT
        </div>
        <a class="address-content"
           v-for="(payment, i) in get(state, 'currentUser.paymentMethods', []).filter(p => p.type === 'payment' && p.isValid)"
           :key="i" @click="setAddressIndex(payment.id)">
          <div style="width: 25px; justify-content: center; align-items: center">
            <img src="@/assets/images/icon_check.png" style="width: 20px" v-if="payment.isDefault">
          </div>
          <img :src="payment.image" style="width: 40px; margin-left: 10px;">
          <div class="address-text ml-4 mr-2" :style="payment.isDefault ? 'opacity: 1': 'opacity: 0.4'">
            {{`Card ending in ${payment.last4}`}}
          </div>
          <button class="btn edit" @click.stop.prevent="onDeletePayment(payment)">
            DELETE
          </button>
        </a>
      </div>
      <button class="btn btn-login" style="width: 98%; margin-top: 30px" type="button" @click="onPressContinue">
        ADD PAYMENT METHOD
      </button>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {get} from 'lodash';

  export default {
    props: ['selectedIndex', 'updateSelectPayment'],
    setup(props, {root}) {
      const data = reactive({
        index: null,
      });
      const onPressContinue = () => {
        root.$router.push('/addPayment')
      };

      const setAddressIndex = async (id) => {
        data.index = id;
        console.log(data.index, id)
        await root.actions.startLoading();
        try {
          await root.actions.paymentMethod.setPaymentMethodToDefault(id);
          if (props.updateSelectedPayment) {
            props.updateSelectedPayment(id);
          }
        } catch (e) {
          console.log(e);
        } finally {
          await root.actions.finishLoading();
        }
      };

      const onDeletePayment = (payment) => {
        if (payment.isDefault) {
          root.actions.alert.showError({message: 'Choose another default payment method!'});
        } else {
          const loader = root.$loading.show();
          try {
            const methods = [];
            methods.push(payment.token);
            root.actions.user.saveUser({removePaymentMethods: methods});
          } catch (e) {
            console.log(e);
          } finally {
            loader.hide();
          }
        }
      };
      return {
        onPressContinue,
        setAddressIndex,
        onDeletePayment,
        get,
        ...toRefs(data)
      }
    },
    beforeUpdate() {
      if (this.selectedIndex) {
        this.index = this.selectedIndex;
      }
    },
  }
</script>

<style lang="css" scoped>
  input:focus {
    outline-width: 0 !important;
  }

  .edit {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 2px;
    width: 50px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 8px;
    line-height: 23px;
    color: #727272;
    background-color: white;
    position: absolute;
    right: 25px;
    padding: 0;
  }

  .address-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px;
    margin-top: 5px;
  }

  .address-text {
    font-weight: 600;
    font-size: 12px;
    color: black;
  }
</style>
